import(/* webpackMode: "eager" */ "/build/ddb09d53-6050-4300-aa56-684e67ef7e36/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/build/ddb09d53-6050-4300-aa56-684e67ef7e36/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/build/ddb09d53-6050-4300-aa56-684e67ef7e36/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/build/ddb09d53-6050-4300-aa56-684e67ef7e36/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/build/ddb09d53-6050-4300-aa56-684e67ef7e36/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/build/ddb09d53-6050-4300-aa56-684e67ef7e36/node_modules/next/dist/client/components/render-from-template-context.js");
